import React, { useEffect, useState } from "react";
import "./Header.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Logo from "../../Assets/Images/The life Plus-all logo_Page_3.png";
import DesktopDropdown from "../Dropdown/DesktopDropdown";
import CityDropdown from "../Dropdown/CityDropdown";
import { BsArrowRightShort, BsCart3 } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { getAllDepartments } from "../../Network calls/Allroutes";
import lifeLogo from "../../Assets/Images/The life Plus-all logo_Page_5.png";
import {
  MdArrowBack,
  MdArrowDropDown,
  MdArrowDropDownCircle,
  MdArrowForward,
  MdMenu,
} from "react-icons/md";
import slugify from "slugify";
import AddtocartDropdown from "../Dropdown/AddtocartDropdown";

const Header = () => {
  const [department, setDepartment] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [visibleDepartments, setVisibleDepartments] = useState(11);
  const [isHovered, setIsHovered] = useState(false);
  const [hovered, setHovered] = useState(null);

  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [hoveredSubDepartment, setHoveredSubDepartment] = useState(null);

  const navigate = useNavigate();
  const [screen, setScren] = useState();
  const handleClick = (slug) => {
    // history(`/department/${slug}`);
    window.location.href = `/department/${slug}`;
  };
  const handleMouseLeave = () => {
    setHovered(null);
  };
  const handleMouseEnter = (key) => {
    setHovered(key);
  };

  const settings = {
    infinite: false,
    dots: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 5,
    autoplay: false,
    autoplaySpeed: 1000,
  };
  let inlineStyle = {
    color: isHovered ? "red" : "blue",
    transition: "color 0.3s",
  };

  const handleDepartmentClick = (departmentId) => {
    setSelectedDepartment(
      departmentId === selectedDepartment ? null : departmentId
    );
  };

  const handleSubDepartmentHover = (subDeptId) => {
    setHoveredSubDepartment(subDeptId);
  };

  const handleMoreClick = () => {
    setShowAll(!showAll);
    setVisibleDepartments(showAll ? 11 : department.length);
  };

  useEffect(() => {
    getAllDepartments().then((res, err) => {
      if (res?.message === "Sucess") {
        setDepartment(res.data);
        console.log(res.data);
      }
    });
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setScren(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {window.innerWidth <= 546 ? (
        <>
          <>
            <div
              style={{
                position: "fixed",
                top: "0px",
                width: "100%",
                zIndex: "999",
              }}
            >
              <nav
                className="navbar navbar-expand-lg p-1"
                style={{
                  background: "#273960",
                  fontSize: "15px",
                  fontWeight: "400",
                }}
              >
                <div className="container-fluid">
                  <Link className="navbar-brand" to={"/"}>
                    <img
                      src={lifeLogo}
                      className="img-fluid bg-white rounded rounded-3"
                      style={{ height: "60px" }}
                      alt={"logo"}
                      title="logo"
                    />
                  </Link>
                  <button
                    className="navbar-toggler"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarSupportedContent"
                    aria-controls="navbarSupportedContent"
                    aria-expanded="false"
                    aria-label="Toggle navigation"
                  >
                    <MdMenu className="text-white" fontSize={30} />
                  </button>
                  <div
                    className={`collapse navbar-collapsee text-white`}
                    id="navbarSupportedContent"
                  >
                    <div
                      className="vh-100"
                      style={{ overflowY: "scroll", paddingBottom: "90px" }}
                    >
                      <div className="row">
                        <div className="col-6 text-center  pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>Overview</>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 text-center pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/about"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>About</>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 text-center pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/doctors"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>Doctors</>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 text-center pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/labs"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>Labs</>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 text-center pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/pharmacy"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>Pharmacy</>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 text-center pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/blog"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>Blogs</>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 text-center pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/careers"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>Careers</>
                            </Link>
                          </div>
                        </div>
                        <div className="col-6 text-center pt-2">
                          <div className="border border-white rounded rounded-2 p-2">
                            <Link
                              to={"/contactus"}
                              className="nav-link active text-white"
                              aria-current="page"
                              href="#"
                            >
                              <>Contact US</>
                            </Link>
                          </div>
                        </div>
                      </div>
                      <div
                        className="mt-3"
                        style={{ fontSize: "18px", fontWeight: "600" }}
                      >
                        Our Specialities{" "}
                      </div>
                      <ul className="navbar-nav text-start mb-2 mb-lg-0">
                        {department?.map((data, key) => {
                          return (
                            <>
                              <li className="nav-item dropdown" key={key}>
                                <Link
                                  className="nav-link dropdown-toggle text-white"
                                  href="#"
                                  id="navbarDropdown"
                                  role="button"
                                  data-bs-toggle="dropdown"
                                  aria-expanded="false"
                                  to={data.department.toLowerCase()}
                                  style={{
                                    fontSize: "16px",
                                    fontWeight: "400",
                                  }}
                                >
                                  {data.department}
                                </Link>
                                <ul
                                  className="dropdown-menu"
                                  aria-labelledby="navbarDropdown"
                                >
                                  {data?.subdepartments?.map(
                                    (subdep, subKey) => {
                                      // const slug = slugify(subdep.name, {
                                      //   lower: true,
                                      // });
                                      return (
                                        <>
                                          <li
                                            key={subKey}
                                            className="d-flex justify-content-between pe-2"
                                          >
                                            <div>
                                              <a
                                                className="dropdown-item text-primary"
                                                href={`/treatmentname/${subdep?.slug}`}
                                              >
                                                {subdep.name.slice(0, 15)}
                                              </a>
                                            </div>
                                            <div>
                                              <a
                                                href={`/treatmentname/${subdep?.slug}`}
                                              >
                                                <BsArrowRightShort
                                                  className="text-primary"
                                                  fontSize={30}
                                                />
                                              </a>
                                            </div>
                                          </li>
                                        </>
                                      );
                                    }
                                  )}
                                </ul>
                              </li>
                              <hr className="mb-1" />
                            </>
                          );
                        })}
                      </ul>

                      <form className="d-flex">
                        <ul className="navbar-nav">
                          <li className="nav-item p-2">
                            <a
                              className="nav-link p-2  text-white bg-danger rounded rounded-3"
                              href="https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D"
                              // id="navbarDropdown"
                              target="_blank"
                              role="button"
                              // data-bs-toggle="dropdown"
                              aria-expanded="false"
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              Book Appointment
                            </a>
                            {/* <ul
                              className="dropdown-menu"
                              aria-labelledby="navbarDropdown"
                            >
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    window.location.href =
                                      "https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D";
                                    localStorage.setItem(
                                      "booking type",
                                      "online"
                                    );
                                  }}
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  Video Consultancy
                                </button>
                              </li>
                              <li>
                                <button
                                  className="dropdown-item"
                                  onClick={() => {
                                    window.location.href =
                                      "https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D";
                                    localStorage.setItem(
                                      "booking type",
                                      "offline"
                                    );
                                  }}
                                  style={{
                                    fontSize: "14px",
                                    fontWeight: "400",
                                  }}
                                >
                                  In Hospitals
                                </button>
                              </li>
                            </ul> */}
                          </li>
                        </ul>
                        <div className="ms-3 text-white my-auto">
                          <CityDropdown />
                        </div>
                        <div className="ms-3 text-white my-auto">
                          <AddtocartDropdown />
                        </div>
                        {/* <div className='ms-3 me-3 text-white my-auto' style={{ cursor: "pointer" }} onClick={() => navigate('/addtocart')}>
                          <BsCart3 fontSize={18} />
                        </div> */}
                      </form>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </>
        </>
      ) : (
        <>
          <div
            style={{
              position: "fixed",
              top: "0px",
              width: "100%",
              zIndex: "1001",
            }}
          >
            <nav
              className="navbar navbar-expand-lg p-0"
              style={{
                background: "#273960",
                fontSize: "15px",
                fontWeight: "400",
                zIndex: "1000",
              }}
            >
              <div className="container-fluid ps-5 pe-5">
                <Link className="navbar-brand" to={"/"}>
                  <img
                    src={lifeLogo}
                    className="img-fluid bg-white rounded rounded-3"
                    style={{ height: "60px" }}
                    alt="logo"
                    title="logo"
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
                    <li className="nav-item">
                      <Link
                        to={"/"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>Overview</>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/about"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>About</>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/doctors"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>Doctors</>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/labs"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>Labs</>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/pharmacy"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>Pharmacy</>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/blog"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>Blogs</>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/careers"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>Careers</>
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to={"/contactus"}
                        className="nav-link active text-white"
                        aria-current="page"
                        href="#"
                      >
                        <>Contact Us</>
                      </Link>
                    </li>
                  </ul>
                  <form className="d-flex">
                    <ul className="navbar-nav ">
                      <li className="nav-item p-2">
                        <a
                          className="nav-link p-2  text-white bg-danger rounded rounded-3"
                          target="_blank"
                          href="https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D"
                          // id="navbarDropdown"
                          role="button"
                          // data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ fontSize: "14px", fontWeight: "400" }}
                        >
                          Book Appointment
                        </a>
                        {/* <ul
                          className="dropdown-menu"
                          aria-labelledby="navbarDropdown"
                        >
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                window.location.href = `https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D`;

                                // navigate("/bookappoinment");
                                // localStorage.setItem("booking type", "online");
                              }}
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              Video Consultancy
                            </button>
                          </li>
                          <li>
                            <button
                              className="dropdown-item"
                              onClick={() => {
                                window.location.href = `https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D`;

                                // navigate("/bookappoinment");
                                // localStorage.setItem("booking type", "offline");
                              }}
                              style={{ fontSize: "14px", fontWeight: "400" }}
                            >
                              In Hospitals
                            </button>
                          </li>
                        </ul> */}
                      </li>
                    </ul>
                    <div className="ms-3 text-white my-auto">
                      <CityDropdown />
                    </div>
                    <div className="ms-3 text-white my-auto">
                      <AddtocartDropdown />
                    </div>
                    {/* <div className='ms-3 me-3 text-white mt-1' style={{ cursor: "pointer" }} onClick={() => navigate('/addtocart')}><BsCart3 fontSize={18} /></div> */}
                  </form>
                </div>
              </div>
            </nav>
            {/* <div className='container-fluid d-flex justify-content-center text-white pt-2 pb-2' style={{ background: "#273960" }}>
              {department ? (
                department.slice(0, 12).map((d, key) => (
                  <div
                    key={d._id}
                    className='department-item me-3'
                    style={{ fontSize: "14px", fontWeight: "400" }}
                    onClick={() => handleDepartmentClick(d._id)}
                  >
                    {d.department}<MdArrowDropDown />
                    {selectedDepartment === d._id && (
                      <div className='sub-departments' style={{ zIndex: "1" }}>
                        {d?.subdepartments?.map((subDept, index) => (
                          <div key={index}>{subDept.name}</div>
                        ))}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>Loading...</p>
              )}
            </div> */}

            {/* <div className="container-fluid ps-5 pe-5" style={{ fontSize: "14px", fontWeight: "400", cursor: "pointer" }}>
              <Slider {...settings}>
                {department?.map((d, index) => (
                  <div className='bg-white ps-1 pe-1 pt-2 pb-2 text-dark text-center' style={{ whiteSpace: "nowrap" }} onClick={() => handleDepartmentClick(d._id)}>
                    <div style={{ fontWeight: "600" }}>{d.department.slice(0, 10)} <MdArrowDropDown /></div>
                    {selectedDepartment === d._id && (
                      <div className='bg-white text-dark mt-1 text-start' style={{ zIndex: "1" }}>
                        {d?.subdepartments?.map((subDept, index) => (
                          <div key={index} className='p-1'
                            style={{
                              color: hoveredSubDepartment === subDept._id ? 'blue' : 'black',
                              transition: 'color 0.3s',
                            }}
                            onMouseEnter={() => handleSubDepartmentHover(subDept._id)}
                            onMouseLeave={() => handleSubDepartmentHover(null)}
                            onClick={() => navigate(`/treatment/${subDept._id}`)}>{subDept?.name?.slice(0, 20)}...</div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
              </Slider>
            </div> */}

            <nav
              className="navbar navbar-expand-lg shadow p-0"
              style={{
                fontSize: "15px",
                fontWeight: "400",
                background: "#273960",
              }}
            >
              <div className="container-fluid pt-1 pb-1 ps-5 pe-5">
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>

                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav mx-auto">
                    {department?.slice(0, 12).map((data, key) => (
                      <li
                        className={`nav-item dropdown ${
                          hovered === key ? "show" : ""
                        }`}
                        key={key}
                        onMouseEnter={() => handleMouseEnter(key)}
                        onMouseLeave={handleMouseLeave}
                      >
                        <span
                          className="nav-link dropdown-toggle text-white"
                          id="navbarDropdown"
                          role="button"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                          style={{ fontSize: "14px", fontWeight: "400" }}
                          onClick={() => handleClick(data?.slug)}
                        >
                          {data?.department}
                        </span>
                        <ul
                          className={`dropdown-menu ${
                            hovered === key ? "show" : ""
                          }`}
                          aria-labelledby="navbarDropdown"
                          style={{
                            fontSize: "14px",
                            fontWeight: "400",
                            overflowX: "scroll",
                            maxWidth: "200px",
                            overflowY: "scroll",
                            maxHeight: "300px",
                          }}
                        >
                          {data?.subdepartments?.map((subdep, subKey) => {
                            const slug = slugify(subdep.name, { lower: true });
                            return (
                              <li key={subKey}>
                                <a
                                  className="dropdown-item pe-2"
                                  href={`/treatmentname/${subdep?.slug}`}
                                >
                                  {subdep.name}
                                </a>
                              </li>
                            );
                          })}
                        </ul>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </>
      )}
    </>
  );
};

export default Header;
