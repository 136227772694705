import axios from "axios";

const BASE_URL = "https://api.thelifeplushospital.com";
// const BASE_URL = "http://localhost:5000";

export const axiosPublic = axios.create({
  baseURL: BASE_URL,
});

export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: false,
});
