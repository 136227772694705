import React, { useState } from "react";
import { BiListCheck } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const BookapoointmentButton = () => {
  const navigate = useNavigate();
  const [isHovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };
  return (
    <div>
      <div
        className={`rounded ${
          isHovered ? "rounded-5" : "rounded-circle"
        } shadow d-flex text-center justify-content-${
          isHovered ? "between" : "center"
        } align-items-center p-${isHovered ? "2" : "0"}`}
        style={{
          width: isHovered ? "200px" : "50px",
          cursor: "pointer",
          height: "50px",
          background: "rgb(197, 28, 34)",
          position: "fixed",
          top: "60%",
          right: "0px",
          zIndex: 999,
          transition:
            "width 0.3s ease, height 0.3s ease, border-radius 0.3s ease",
        }}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={() =>
          // navigate("/bookappoinment")
          (window.location.href =
            "https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D")
        }
      >
        {isHovered ? (
          <>
            <div>
              <BiListCheck className="text-white" />
            </div>
            <div className="text-white">Book Appointment</div>
          </>
        ) : (
          <>
            <div>
              <BiListCheck className="text-white" />
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default BookapoointmentButton;
