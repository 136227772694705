import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Footer.css";
import footerIcon from "../../Assets/Images/The life Plus-all logo_Page_5.png";
import { BsTelephone } from "react-icons/bs";
import { CiMail, CiMobile3 } from "react-icons/ci";
import { FaFacebook } from "react-icons/fa";
import {
  AiFillInstagram,
  AiFillLinkedin,
  AiFillTwitterCircle,
  AiFillYoutube,
} from "react-icons/ai";
import { Link, useNavigate } from "react-router-dom";
import { getFooter } from "../../Network calls/Allroutes";
import slugify from "slugify";

const Footer = () => {
  const [department, setDepartment] = useState();
  const [footerData, setFooterData] = useState([]);

  useEffect(() => {
    getFooter().then((res) => {
      if (res?.message === "Sucess") {
        setDepartment(res.data[0].department);
        setFooterData(res.data[0]);
      }
    });
  }, []);
  const navigate = useNavigate();
  return (
    <div className="footer">
      <Container fluid className="footer-conatiner">
        <Row>
          <Col>
            <Container>
              <Row>
                <Col sm={12} md={3} lg={3} className="first-section">
                  <ul>
                    <li>
                      <div className="img-icon-footer">
                        <img
                          src={footerData?.hospital_logo}
                          alt="logo"
                          title="logo"
                        />
                      </div>
                    </li>
                    <li>Address: {footerData?.hospital_address}</li>
                    <li>
                      <CiMobile3 />
                      <span> +91 {footerData?.phone_number}</span>
                    </li>

                    <li>
                      <BsTelephone />
                      <span> {footerData?.telephone_number}</span>
                    </li>
                    <li>
                      <CiMail />
                      <span> {footerData?.email}</span>
                    </li>
                  </ul>
                </Col>
                <Col className="second-section">
                  <ul>
                    <li>
                      <h5>Get More Links</h5>
                    </li>
                    <Link
                      to={"/"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Overview</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/about"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>About Us</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/doctors"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Doctors</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/blog"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Blogs</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/careers"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Careers</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/contactus"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Contact Us</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/privacypolicy"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Privacy Policy</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/termsandcondition"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Terms & Conditions</>
                    </Link>{" "}
                    <br />
                    <Link
                      to={"/refundpolicy"}
                      style={{ textDecoration: "none" }}
                      className="text-white"
                    >
                      <>Cancellation/Refund Policy</>
                    </Link>{" "}
                    <br />
                  </ul>
                </Col>
                <Col className="third-section">
                  <ul>
                    <li>
                      <h5>Department</h5>
                    </li>
                    {department?.map((d, key) => {
                      return (
                        <li style={{ cursor: "pointer" }}>
                          <Link
                            to={`/department/${d.slug}`}
                            className="text-white"
                            style={{ textDecoration: "none" }}
                          >
                            {d.department}
                          </Link>
                        </li>
                      );
                    })}
                  </ul>
                </Col>
                <Col className="fourth-section">
                  <ul>
                    <li>
                      <h5>Find us</h5>
                      <li>
                        {" "}
                        <iframe
                          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d971.9847382171388!2d77.64636116958931!3d12.975756399208752!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae16a94854d253%3A0x5e318474b0a05d3a!2sThe%20Life%20Plus%20Multispecialty%20Hospital%20%7C%20Best%20Gynecologist%20in%20Bangalore!5e0!3m2!1sen!2sin!4v1725350240860!5m2!1sen!2sin"
                          width="230px"
                          height="130px"
                          style={{ borderRadius: "12px" }}
                          allowfullscreen=""
                          loading="lazy"
                          referrerpolicy="no-referrer-when-downgrade"
                        ></iframe>
                      </li>
                    </li>
                    <li>Follow us on</li>
                    <li>
                      <a
                        className="text-white"
                        target="_blank"
                        style={{ textDecoration: "none" }}
                        href="https://www.facebook.com/thelifeplushospitalindranagar/"
                      >
                        <FaFacebook fontSize={20} />{" "}
                      </a>
                      <span>
                        <a
                          className="text-white"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          href="https://www.instagram.com/thelifeplushospitalindranagar/"
                        >
                          <AiFillInstagram fontSize={23} />
                        </a>{" "}
                      </span>
                      <span>
                        <a
                          className="text-white"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          href="https://www.linkedin.com/in/the-life-plus-multispecialty-hospital-585671158/"
                        >
                          <AiFillLinkedin fontSize={23} />
                        </a>{" "}
                      </span>
                      <span>
                        <a
                          className="text-white"
                          target="_blank"
                          style={{ textDecoration: "none" }}
                          href="https://www.youtube.com/@thelifeplushospital-indira8708/videos"
                        >
                          <AiFillYoutube fontSize={24} />
                        </a>
                      </span>
                    </li>
                    <li>
                      <button
                        className="mb-3"
                        onClick={() => {
                          window.location.href =
                            "https://medicsprime.in/OnlineAppointment/OnlineAppointment.jsp?enx=%7B%22e%22%3A%22ZTsL9YRMQeLUY9PqfpILjCFR%2FDOWC29nQoxTqA5%2BRhkoJFKme95AWVdDJqv95Kk9uMj8d7v1mgnQwnQ%2Bn0RjRg%3D%3D%22%2C%22s%22%3A%225df5972e506d6c48be1be367428d8159%22%2C%22i%22%3A%22adc092a2ffc59c21dc8dcd1d3f5b0038%22%7D";
                          // setbookappsource(
                          //   "Why LifePlus Hospital",
                          //   "whylifeplus"
                          // );
                          // openLeadPopup();
                          // navigate("/bookappoinment");
                        }}
                      >
                        Book Appointment
                      </button>
                    </li>
                  </ul>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>{" "}
    </div>
  );
};

export default Footer;
