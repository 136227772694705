import React from 'react';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { BiTestTube } from 'react-icons/bi';
import { BsCart3 } from 'react-icons/bs';
import { FaUserDoctor } from 'react-icons/fa6';
import { GiMedicinePills } from 'react-icons/gi';
import { TfiLocationPin } from 'react-icons/tfi';
import { Link } from 'react-router-dom';

const AddtocartDropdown = () => {
    return (
        <div>
            <NavDropdown
                id='nav-dropdown-dark-example'
                title={<BsCart3 fontSize={18} />}
            >
                <NavDropdown.Item >
                    <Link to={'/addtocart'} style={{ textDecoration: "none", color: "black", cursor: "pointer", fontSize: "14px", fontWeight: "400" }}>
                        <FaUserDoctor fontSize={16} color='darkblue' /> Consultancy Cart
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item >
                    <Link to={'/addtocartpharma'} style={{ textDecoration: "none", color: "black", cursor: "pointer", fontSize: "14px", fontWeight: "400" }}>
                        <GiMedicinePills fontSize={16} color='darkblue' /> Pharmacy Cart
                    </Link>
                </NavDropdown.Item>
                <NavDropdown.Item >
                    <Link to={'/addtocartlab'} style={{ textDecoration: "none", color: "black", cursor: "pointer", fontSize: "14px", fontWeight: "400" }}>
                        <BiTestTube fontSize={16} color='darkblue' /> Lab Cart
                    </Link>
                </NavDropdown.Item>
            </NavDropdown>
        </div>
    );
};

export default AddtocartDropdown;
